//
// Override global variables
//

// Theme colors
$primary:       									#000;
$primary-hover:    									#777777;
$primary-light:    									#a1a1a1;
$primary-inverse:  									#f1f1f1;

$info:       									    #6993FF;
$info-light:    									#E1E9FF;
$info-hover:    									#4A7DFF;
$info-inverse:  									#FFFFFF;

$success:       									#3699FF;
$success-hover:    									#187DE4;
$success-light:    									#E1F0FF;
$success-inverse:  									#FFFFFF;
