/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #e9f3ed;
}
input:checked {
  background-color: #521eee;
}

@media only screen and (max-width: 600px) {
  /* .flex{
      width: 200px;     
    } */
  .logoimg {
    width: 100% !important;
    height: 100px;
  }
  .logosvg {
    width: 20px;
    height: 20px;
    margin-top: -10px;
  }
  .svg {
    width: 20px;
    height: 20px;
    margin-top: -67px;
    vertical-align: initial !important;
  }
  .fetureimg {
    width: 150px !important;
    height: 100px !important;
  }
  .bannerimg {
    width: 210px !important;
    height: 100px !important;
  }
  .title {
    font-size: 25px;
  }
}
.category-select {
  width: 250px;
}
.category-select .control {
  box-shadow: none;
}
.gap {
  gap: 20px;
}
